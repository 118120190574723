@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");


footer {
    height: 70vh;
    padding: 24px 24px;
    display: flex;
}

footer > .container {
    margin: auto;
    display: flex;
    flex-direction: column;
}

footer .row {
    margin: 12px -12px;
}

footer .row .col-30 {
    margin: 0px 12px;
}

.footer-text__title {
    color: #000;
    font-size: 18px;
    margin-bottom: 48px;
    font-weight: 700;
}

.footer-text__disclose {
    color: #707b8e;
    font-size: 1.0rem;
    line-height: 1.8;
    margin: 8px 0px;
}

.footer-text__body {
    color: #707b8e;
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 8px 0px;
}

.footer-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.footer-list li {
    margin: 8px 0px;
    transition: 0.1s all ease-in-out;
}

.footer-list li:hover {
    margin-left: 6px;
}

.footer-list li a {
    display: block;
    text-decoration: none;
    color: #868c98;
    font-weight: 300;
    font-size: 1.1rem;
}

.footer-input {
    display: flex;
}

.footer-input input {
    background-color: #888;
    background: #f9f9fe;
    padding: 10px 14px;
    border: 0px;
    width: 190px;
}

.footer-input__icon {
    color: #fff;
    font-size: 1.5rem;
    background: #1B214F;
    display: flex;
    padding: 10px 14px;
}

.copyright {
    color: #888;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    margin-top: 24px;
    margin: 0px auto;
}

@media screen and (max-width: 700px) {
    footer {
        height: auto;
    }

    footer .row {
        flex-direction: column;
    }

    footer .row .col-30 {
        width: calc(100% - 24px);
        margin: 12px auto;
    }

    .footer-text__title {
        margin-bottom: 12px;
    }
}

/* Style all font awesome icons */
.fa {
    padding: 15px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: white;
}

/* Add a hover effect if you want */
.fa:hover {
    opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
    background: #3B5998;
    margin-left: 10px;
    margin-right: 10px;
}

.fa-instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

/* Twitter */
.fa-twitter {
    background: #55ACEE;
    color: white;
}
