@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600,700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
html,
body {
    width: 100%;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

main {
    z-index: 100;
}

main > *:nth-child(odd) {
    background-color: #fff;
}

.commitment-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.commitment-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: -20px;
}

.commitment-row-desr {
    margin-top: 20px;
    color: #707b8e;
    font-size: 0.9rem;
    line-height: 30px;
    font-weight: normal;
}

.commitment-title {
    max-width: unset !important;
}

.commitment-subtitle {
    max-width: unset !important;
    font-size: 30px !important;
    margin-bottom: 0px !important;
    margin-top: 20px !important;
}

.commitment-body {
    max-width: unset !important;
    width: auto !important;
    margin-top: 20px;
}

section {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
    padding: 100px 24px;
}

.center-content {
    align-items: center;
}

.center-content-vertical {
    height: 70vh;
    justify-content: center;
}

section > .container {
    display: flex;
    height: 100%;
    margin: 0px auto;
    max-width: 1440px;
}

section > .container > * {
    margin: auto 12px;
}

.header-logo {
    width: 100px;
    margin-left: 50px;
    margin-top: 15px;
}

.check-mark {
    width: 150px;
}

.trade-mark {
    font-weight: bold;
    color: #FF6F00;
}

.commission-image {
    width: 150px;
    height: 150px;
}

.payment-iframe {
    height: 50px;
    width: 360px;
}

.payment-form {
    margin-top: 50px;
}

.input-medium {
    width: 356px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #cccccc;
    padding: 10px;
}

@media screen and (max-width: 700px) {
    section {
        height: auto;
    }

    section > .container {
        display: block;
    }

    section > .container > * {
        margin: 12px auto;
        width: 100%;
    }
}

.section-text {
    min-width: 300px;
}

.section-text__title {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 40px;
    color: #1B214F;
    max-width: 400px;
}

.section-text__title-centered {
    max-width: 600px;
    text-align: center;
    margin: 0px auto;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 40px;
    color: #1B214F;
}

.section-text__title-big {
    font-size: 4rem;
    font-weight: 700;
    color: #1B214F;
    line-height: 1.3;
    margin-bottom: 24px;
    max-width: 600px;
}

.margin-bottom-small {
    margin-bottom: 10px !important;
}

.section-text__title-small {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
    color: #FF6F00;
}

.section-text__subtitle {
    color: #707b8e;
    font-size: 1.2rem;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 400;
    max-width: 400px;
}

.section-text__body {
    color: #707b8e;
    font-size: 0.9rem;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: normal;
    max-width: 300px;
}

.justify-text {
    text-align: justify;
}

.section-image {
    height: calc(100vh - 130px);
}

.section-image__small {
    height: calc(100vh - 130px);
    max-width: 60vw;
}

.section-image img,
.section-image__small img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width: 700px) {
    .section-image,
    .section-image__small {
        display: none;
    }
}

.feature-box {
    margin-bottom: 32px;
}

.service-cards {
    display: flex;
    margin: 0px auto;
    justify-content: center;
}

.service-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 90px 18px;
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid transparent;
    margin: 12px 8px;
    transition: 0.1s all ease-in;
}

.service-card:hover,
.service-card.active {
    border: 1px solid #FF6F00;
}

.service-card > * {
    margin: 0px auto;
}

.service-card__icon {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 12px auto;
    margin-bottom: 24px;
    border-radius: 50%;
    background-color: #FF6F00;
    transition: 0.1s all ease-in;
}

.service-card__icon ion-icon {
    color: #fff;
    margin: auto;
    font-size: 3rem;
}

.service-card:hover .service-card__icon,
.service-card.active .service-card__icon {
    background-color: #4d4d4d;
}

@media screen and (max-width: 700px) {
    .service-cards {
        flex-direction: column;
    }
}

.fundnow-btn {
    border-color: transparent;
    width: 300px;
    margin-top: 100px;
    flex-direction: row;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.download-btn {
    background-color: #FF6F00;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 300;
    padding: 16px 40px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.2s linear;
    margin: 20px 0 0;
}

.download-btn:hover {
    background-color: #FF8E00;
    color: #ffffff;
}

.section-carousel__images {
    display: flex;
}

.section-carousel__image {
    margin: 0px 6px;
}

@media screen and (max-width: 700px) {
    .section-carousel__images {
        position: relative;
        right: 0;
        left: 0;
    }
}

section#pricing {
    height: auto;
    padding: 0px;
}


.pricing-lower {
    height: 70vh;
    margin-top: -20vh;
    padding: 0px 24px;
}

.pricing-cards {
    display: flex;
    justify-content: center;
}

.pricing-card {
    width: calc(30vw - 12px);
    margin: 0px 6px;
    max-width: 300px;
    padding: 52px 24px;
    background: #fff;
    border-radius: 25px;
    text-align: center;
    position: relative;
    z-index: 20;
    transition: 0.2s all ease-in-out;
}

.pricing-card:hover {
    box-shadow: 0 10px 30px 0 rgba(133, 66, 189, 0.1);
}

/* .pricing-card::before {
  content: "";
  width: 70px;
  height: 130px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  background-image: url("./images/dot-bg.webp");
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover::before {
  right: -25px;
  bottom: -27px;
} */
.pricing-card__header {
    border-bottom: 2px solid #f4f4f4;
    margin-bottom: 46px;
}

.pricing-card__title {
    color: #6a56a6;
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 26px;
}

.pricing-card__title > span {
    vertical-align: baseline;
    font-size: 40%;
}

.pricing-card__subtitle {
    color: #57667e;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 19px;
    display: inline-block;
}

.pricing-card__features {
    list-style-type: none;
    margin: 12px 0px;
    padding: 0px;
}

.pricing-card__features > li {
    margin: 8px 0px;
    color: #717081;
    font-weight: 300;
    font-size: 1.1rem;
}

.pricing-card__btn {
    display: block;
    margin-top: 24px;
    position: relative;
    cursor: pointer;
    background-color: #712fda;
    border-radius: 6px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 16px 28px;
    border: 0px;
    color: #fff;
    transition: 0.2s all ease-in-out;
}

.pricing-card:hover .pricing-card__btn {
    background-color: #ff374b;
}

@media screen and (max-width: 700px) {
    .pricing-lower {
        height: auto;
    }

    .pricing-cards {
        flex-direction: column;
    }

    .pricing-card {
        width: calc(100% - 12px);
        margin: 12px auto;
    }
}

section#download {
    position: relative;
    display: flex;
    padding: 0;
}

.row {
    display: flex;
    flex-direction: row;
}

.footer {
    width: 100%;
    height: 150px;
    background-image: -webkit-linear-gradient(
            0deg,
            rgb(255, 113, 0) 0%,
            rgb(250, 201, 160) 100%
    );
    margin-top: -120px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footer .content {
    padding-top: 30px;
    height: 50px;
}

.footer .bottom-border {
    height: 50px;
}

section#download .container {
    margin: auto;
}

section#download .section-image {
    margin-left: 10vw;
    max-width: 30vw;
    height: calc(100% - 0px);
}

.download-btn__img {
    display: inline-block;
    height: 56px;
    width: 170px;
    margin: 0px 8px;
    margin-top: 24px;
    transition: 0.2s all ease-in-out;
}

.download-btn__img:hover {
    opacity: 0.9;
}

.section-image_small {
    width: auto;
}

.image_small {
    min-width: 140px;
    max-width: 150px;
}

@media screen and (max-width: 700px) {
    section#download {
        height: auto;
    }
}

.text-white {
    color: #fff !important;
}

.row {
    display: flex;
    margin: 0px -14px;
    justify-content: space-between;
}

.col-50 {
    width: 50%;
    padding: 0px 14px;
}

.col-30 {
    width: 30%;
    padding: 0px 14px;
}


.nav {
  width: calc(100% - 0px);
  position: absolute;
  padding: 0px 12px;
  top: 0px;
  left: 0px;
  display: flex;
  height: 70px;
  overflow: hidden;
  z-index: 1000;
}
.nav-stickey {
  -webkit-animation: nav-enter-anim 0.15s ease-in;
          animation: nav-enter-anim 0.15s ease-in;
  background-color: #fff;
  position: fixed;
  box-shadow: 0px -6px 24px -1px rgba(148, 148, 148, 1);
}

.nav-content {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.nav-logo {
  height: 100%;
  display: inline-flex;
  margin: 8px 8px;
}

.nav-links__container {
  display: flex;
  margin-left: auto;
}
.nav-link {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  margin: 0px 8px;
}
.nav-link-footer {
  min-width: 120px;
  text-align: center;
}
.nav-link-footer-small {
  font-size: 0.8rem !important;
  margin-top: 30px;
}
.nav-link__text {
  margin: auto 0px;
  padding: 0px 12px;
  color: #1B214F;
  font-size: 1.2rem;
  font-weight: 500;
}
.nav-link__background {
  margin-top: -2px;
  height: 2px;
  width: 0%;
  background: #FF6616;
  transition: 0.2s all ease-in;
}
.nav-link:hover .nav-link__background {
  width: 100%;
}

.nav-menu__icon {
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: auto 0px;
  border-radius: 5px;
  transition: 0.1s all ease-in;
}
.nav-menu__icon div {
  height: 2px;
  width: 100%;
  background-color: #1a1a1a;
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: 0.1s all ease-in;
  margin: 0px auto;
}

.nav-open .nav-menu__icon div:nth-of-type(1) {
  width: 40px;
  margin-left: 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.nav-open .nav-menu__icon div:nth-of-type(2) {
  width: 40px;
  margin-right: 0px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (max-width: 700px) {
  .nav-open {
    height: 100vh;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.95);
    -webkit-animation: nav-open 0.2s ease-in-out;
            animation: nav-open 0.2s ease-in-out;
  }
  .nav-open .nav-content {
    height: 70px;
    margin-top: 0px;
  }
  .nav-links__container {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer .nav-links__container{
    display: flex !important;
    position: unset;
    width: 100%;
    flex-direction: row;
  }
  .footer .nav-link__text {
    font-size: 0.8rem;
    padding: 0 !important;
    min-width: unset !important;
  }
  .nav-link {
    height: 60px;
  }
  .nav-link > * {
    margin-left: auto;
    margin-right: auto;
  }
  .nav-menu__icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .nav-open .nav-menu__icon {
    justify-content: space-between;
  }
}

@keyframes nav-enter-anim {
  from {
    top: -70px;
  }
  to {
    top: 0px;
  }
}
@-webkit-keyframes nav-enter-anim {
  from {
    top: -70px;
  }
  to {
    top: 0px;
  }
}

@keyframes nav-open {
  from {
    height: 70px;
  }
  to {
    height: 100vh;
  }
}
@-webkit-keyframes nav-open {
  from {
    height: 70px;
  }
  to {
    height: 100vh;
  }
}


footer {
    height: 70vh;
    padding: 24px 24px;
    display: flex;
}

footer > .container {
    margin: auto;
    display: flex;
    flex-direction: column;
}

footer .row {
    margin: 12px -12px;
}

footer .row .col-30 {
    margin: 0px 12px;
}

.footer-text__title {
    color: #000;
    font-size: 18px;
    margin-bottom: 48px;
    font-weight: 700;
}

.footer-text__disclose {
    color: #707b8e;
    font-size: 1.0rem;
    line-height: 1.8;
    margin: 8px 0px;
}

.footer-text__body {
    color: #707b8e;
    font-size: 1.1rem;
    line-height: 1.8;
    margin: 8px 0px;
}

.footer-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.footer-list li {
    margin: 8px 0px;
    transition: 0.1s all ease-in-out;
}

.footer-list li:hover {
    margin-left: 6px;
}

.footer-list li a {
    display: block;
    text-decoration: none;
    color: #868c98;
    font-weight: 300;
    font-size: 1.1rem;
}

.footer-input {
    display: flex;
}

.footer-input input {
    background-color: #888;
    background: #f9f9fe;
    padding: 10px 14px;
    border: 0px;
    width: 190px;
}

.footer-input__icon {
    color: #fff;
    font-size: 1.5rem;
    background: #1B214F;
    display: flex;
    padding: 10px 14px;
}

.copyright {
    color: #888;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    margin-top: 24px;
    margin: 0px auto;
}

@media screen and (max-width: 700px) {
    footer {
        height: auto;
    }

    footer .row {
        flex-direction: column;
    }

    footer .row .col-30 {
        width: calc(100% - 24px);
        margin: 12px auto;
    }

    .footer-text__title {
        margin-bottom: 12px;
    }
}

/* Style all font awesome icons */
.fa {
    padding: 15px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: white;
}

/* Add a hover effect if you want */
.fa:hover {
    opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
    background: #3B5998;
    margin-left: 10px;
    margin-right: 10px;
}

.fa-instagram {
    background: #f09433;
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

/* Twitter */
.fa-twitter {
    background: #55ACEE;
    color: white;
}

